import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import TestimonialSection from "../components/testimonialSection"

const TestimonialsPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .map(edge => <TestimonialSection key={edge.node.id} post={edge.node} />)

  return (
    <div>
      <Header />
      <div className="content">
        <br />
        {Posts}
      </div>
      <Footer />
    </div>
  )
}

export default TestimonialsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: {regex : "\/testimonials/"} },
      sort: { order: DESC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          excerpt
          html
          frontmatter {
            title
            anchor
          }
        }
      }
    }
  }
`
